<template>
  <DialogBase 
    :isDialogVisible="dialogOpen"
    :dialogWidth="450"
  >
    <template v-slot:header>
      <span>Complete Task?</span>
    </template>

    <template v-slot:content>
      <p>Mark this task as <span >completed</span>? This change can be undone later.</p>
    </template>

    <template v-slot:footer>
      <v-btn
        text="Cancel"
        variant="text"
        @click="closeDialog"
      ></v-btn>
      <v-btn
        :loading="isConfirmBtnLoading"
        text="Confirm"
        append-icon="mdi-check"
        color="primary"
        variant="tonal"
        @click="completeTask"
      ></v-btn>
    </template>
  </DialogBase>
</template>
  
<script>

import api from '@/services/api';
import DialogBase from './DialogBase.vue';

  export default {
    name: 'TaskConfirmationDialog',
    components: {
      DialogBase
    },
    props: {
      isDialogOpen: {
        type: Boolean,
        default: false
      },
      targetTask: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        hasConfirmed: false,
        isConfirmBtnLoading: false,
        isLightMode: true,
      }
    },
    methods: {
      closeDialog() {
        this.dialogOpen = false;
      },
      async completeTask() {
        this.isConfirmBtnLoading = true;

        try {
          const response = await api.put(`/ContactTask/UpdateContactTaskToCompleted?taskId=${this.targetTask.contact_Task_ID}`);
          await new Promise(resolve => setTimeout(resolve, 500));
          console.log(response.data);
          this.showSnackbar('success', 'mdi-check-circle', 'Successfully Completed Task!', 'Completed ' + this.targetTask.type + ' for ' + this.targetTask.contact_Name);
          this.$emit('refresh-tasks')
          this.closeDialog();
        } catch (error) {
          console.error(error);
          this.showSnackbar('error', 'mdi-alert-circle', 'Was Unable to Complete Task', 'An error occured while attempting to complete this task.');
        } finally {
          this.isConfirmBtnLoading = false;
          this.closeDialog();
        }
      },
      showSnackbar(color, icon, title, description) {
        this.$emit('show-snackbar', {
          color,
          icon,
          title,
          description,
        });
      },
    },
    computed: {
      dialogOpen: {
        get() {
          return this.isDialogOpen;
        },
        set(value) {
          this.$emit('update:is-dialog-open', value);
        }
      }
    }
  }
</script>
