<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Contact Information</span>
    </template>

    <template v-slot:actions>
      <v-chip 
      class="ma-1 mr-2" 
      variant="tonal" 
      color="primary"
      label>
        <v-icon icon="mdi-label-outline" start></v-icon>
        {{ targetContact.contact_ID }}
      </v-chip>
    </template>

    <template v-slot:content>
      <v-row>
        <v-col cols="12" class="pt-4 pb-0">
          <p><strong>Contact Details</strong></p>
        </v-col>

        <v-col cols="12">
          <v-card class="py-4" variant="flat" color="grey-lighten-4" style="border: 1px solid #e0e0e0;">
            <v-row v-for="(field, index) in fields" :key="index" class="px-4">
              <v-col class="text-left" cols="6">
                <strong>{{ field.label }}:</strong>
              </v-col>
              <v-col class="text-right" cols="6">
                {{ targetContact[field.key] === null || targetContact[field.key] === "" ? 'unknown' : targetContact[field.key] }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" class="pt-0" v-if="targetContact.email || targetContact.phone_Primary || targetContact.phone_Direct || targetContact.phone_Mobile">
          <p><strong>Actions</strong></p>
        </v-col>

        <!-- Conditionally Render Phone Alert -->
        <v-col cols="12" class="pt-0" v-if="targetContact.phone_Primary || targetContact.phone_Direct || targetContact.phone_Mobile">
          <v-alert color="purple" density="compact" variant="tonal" transition="slide-x-transition">
            <v-row align="center" no-gutters>
              <v-col>
                <v-icon color="purple" class="mr-2">mdi-check</v-icon>
                Phone Found
              </v-col>
              <v-col class="text-right">
                <v-btn
                  size="small"
                  variant="tonal"
                  prepend-icon="mdi-phone"
                  text="Call"
                  @click="callPhone(primaryPhone)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>

        <!-- Conditionally Render Email Alert -->
        <v-col cols="12" class="pt-0" v-if="targetContact.email">
          <v-alert color="primary" density="compact" variant="tonal" transition="slide-x-transition">
            <v-row align="center" no-gutters>
              <v-col>
                <v-icon color="primary" class="mr-2">mdi-check</v-icon>
                Email Found
              </v-col>
              <v-col class="text-right">
                <v-btn @click="emailContact(targetContact.email)" size="small" variant="tonal" color="primary">
                  <v-icon color="primary" class="mr-2">mdi-email</v-icon>
                  Email
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </template>

    <template v-slot:footer>
      <v-btn text="Close" variant="text" @click="closeDialog"></v-btn>
    </template>
  </DialogBase>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  name: 'DetailedContactDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    targetContact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fields: [
        { label: 'Name', key: 'full_Name' },
        { label: 'Title', key: 'title' },
        { label: 'Email', key: 'email' },
        { label: 'Primary Phone', key: 'phone_Primary' },
        { label: 'Direct Phone', key: 'phone_Direct' },
        { label: 'Mobile Phone', key: 'phone_Mobile' }
      ],
      templateOptions: [
        { title: 'Template 1' },
        { title: 'Template 2' },
        { title: 'Template 3' },
        { title: 'Template 4' }
      ]
    };
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:is-dialog-open', value);
      }
    },
    primaryPhone() {
      return this.targetContact.phone_Primary || this.targetContact.phone_Direct || this.targetContact.phone_Mobile;
    }
  },
  methods: {
    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    resetForm() {
      // Reset logic if needed
    },
    callPhone(phoneNumber) {
      if (phoneNumber) {
        window.location.href = `tel:${phoneNumber}`;
      } else {
        this.showSnackbar('error', 'mdi-alert-circle', 'Phone number not available', 'This contact does not have a valid phone number.');
      }
    },
    emailContact(email) {
      if (email) {
        window.location.href = `mailto:${email}`;
      } else {
        this.showSnackbar('error', 'mdi-alert-circle', 'Email not available', 'This contact does not have a valid email address.');
      }
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000
      });
    }
  }
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
