<template>

<DialogBase 
    :isDialogVisible="dialogOpen"
  >
    <template v-slot:header>
      <span>Create New Task</span>
    </template>

    <template v-slot:content>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Task Information -->
        <v-autocomplete
          variant="outlined"
          density="compact"
          v-model="selectedContact"
          :items="contacts"
          :custom-filter="customFilter"
          item-text="full_Name"
          item-value="contact_ID"
          label="Select Contact"
          :rules="[v => !!v || 'Contact is required']"
          chips
          item-props
          closable-chips
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              color="purple"
              v-bind="props"
              
            >[{{item.raw.contact_ID}}] {{item.raw.full_Name}}</v-chip>
          </template>

          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :subtitle="item.raw.title"
              :title="item.raw.full_Name"
            >
            
              <template v-slot:append>
                <v-chip size="small" variant="tonal" label color="primary">ID:  {{item.raw.contact_ID}}</v-chip>
              </template>
          </v-list-item>
          </template>
        </v-autocomplete>
      
        <v-select
          :items="taskTypes"
          v-model="newTask.type_ID"
          item-title="type"
          item-value="type_ID"
          variant="outlined"
          density="compact"
          label="Task Type"
          :rules="[v => !!v || 'Task type is required']"
        ></v-select>

        <v-textarea
          class="pb-2"
          variant="outlined"
          density="compact"
          v-model="newTask.description"
          label="Task Description"
          :rules="[v => !!v || 'Description is required']"
        ></v-textarea>

        <v-text-field
          variant="outlined"
          density="compact"
          v-model="newTask.action_Date"
          label="Action Date"
          type="date"
        ></v-text-field>
      </v-form>
    </template>

    <template v-slot:footer>
      <v-btn 
        variant="text" 
        @click="closeDialog"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        append-icon="mdi-plus"
        variant="tonal"
        :loading="isAddBtnLoading"
        :disabled="isAddBtnDisabled"
        @click="addTask"
      >Add Task</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';

export default {
  name: 'AddTaskDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAddBtnLoading: false,
      newTask: {
        type_ID: null,
        description: '',
        action_Date: null,
      },
      selectedContact: null,
      contacts: [],
      taskTypes: [],
    };
  },
  async mounted() {
    await this.fetchContacts();
    await this.getTaskTypes();
  },
  methods: {
    async getTaskTypes() {
      try {
        const response = await api.get('/ContactTask/GetContactTaskTypes');
        if (response.status === 200) {
          this.taskTypes = response.data;
        } else {
          console.log('internal error');
        }
      } catch (error) {
        console.error(error);
      }
    },
    customFilter(itemTitle, queryText, item) {
      const textOne = item.raw.full_Name ? item.raw.full_Name.toLowerCase() : '';
      const textTwo = item.raw.title ? item.raw.title.toLowerCase() : '';
      const searchText = queryText ? queryText.toLowerCase() : '';

      return textOne.includes(searchText) || textTwo.includes(searchText);
    },
    async fetchContacts() {
      try {
        const response = await api.get('/Contact/GetAllContacts');
        if (response.status === 200) {
          this.contacts = response.data;
        } else {
          console.error('Failed to fetch contacts');
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    },
    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    async addTask() {
      this.isAddBtnLoading = true;
      const contactTask = {
        Type_ID: this.newTask.type_ID,
        Description: this.newTask.description,
        Contact_ID: this.selectedContact,
        Action_Date: this.newTask.action_Date,
      };
      console.log(this.selectedContact)
      console.log(contactTask)

      try {
        const response = await api.post('/ContactTask/AddContactTask', contactTask);
        await new Promise(resolve => setTimeout(resolve, 500));
        console.log(response.data);
        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Created Task!', 'New task has been created.');
        this.refreshParentCollection();
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Create Task', 'An error occured while attempting to create new task.');
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    resetForm() {
      this.$refs.form.reset();
      this.selectedContact = null;
      this.newTask.description = '';
      this.newTask.type_ID = null;
    },
    refreshParentCollection() {
      this.$emit('refresh-tasks');
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
  computed: {
    isAddBtnDisabled() {
      return !this.selectedContact || !this.newTask.type_ID || this.newTask.description.length < 3;
    },
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      }
    }
  }
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
