<template>
  <v-container>
    <v-select
      density="compact"
      variant="outlined"
      v-model="aggregation"
      :items="aggregationOptions"
      label="Aggregation Property"
      @change="fetchTopPerformers"
    ></v-select>

    <v-row>
      <v-col cols="6" class="pr-1">
        <v-text-field
          v-model="startDate"
          variant="outlined"
          density="compact"
          label="Start Date"
          type="date"
          :error="startDateError"
          @change="validateDates"
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="pl-1">
        <v-text-field
          v-model="endDate"
          variant="outlined"
          density="compact"
          label="End Date"
          type="date"
          :error="endDateError"
          @change="validateDates"
        ></v-text-field>
      </v-col>
    </v-row>

    <div v-if="presenterData.length === 0">
      <v-row class="justify-center mt-8">
        There is no data between these dates...
      </v-row>
      <v-row class="justify-center mt-8">
        <v-btn variant="tonal" color="primary" @click="resetDates"> Reset Dates</v-btn>
      </v-row>
    </div>

    <v-list dark v-else max-height="220" nav dense class="mx-0 pt-0 px-0">
      <v-list-item
        v-for="(item, index) in presenterData"
        :key="index"
        :color="item.color"
        
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.value }}</v-list-item-title>
          <v-list-item-subtitle>Quantity: {{ item.quantity }}</v-list-item-subtitle>
        </v-list-item-content>

        <template v-slot:append>
          <v-chip :color="item.color" label>
            <b>{{ item.quantity }}</b>
            <v-icon class="pl-1" :icon="item.icon"></v-icon>
          </v-chip>
        </template>

      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import api from '@/services/api';

export default {
  name: 'TopContributorsPresenter',
  setup() {
    const presenterData = ref([]);
    const aggregation = ref('Tasks Completed');
    const aggregationOptions = ['Contacts Created', 'Tasks Completed', 'Tasks Created'];
    const startDate = ref(new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10));
    const endDate = ref(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10));
    const startDateError = ref(false);
    const endDateError = ref(false);

    const fetchTopPerformers = async () => {
      if (!aggregation.value) return;
      if (new Date(startDate.value) > new Date(endDate.value)) {
        console.error('Invalid date range');
        return;
      }
      try {
        const response = await api.get('/dashboard/GetTopPerformers', {
          params: {
            aggregationType: aggregation.value,
            startDate: startDate.value,
            endDate: endDate.value
          }
        });
        if (response && response.data && Array.isArray(response.data)) {
          console.log(response)
          presenterData.value = response.data.map((item, index) => ({
            value: item.key,
            quantity: item.value,
            color: getColor(index),
            icon: getIcon(aggregation.value)
          }));
        } else {
          console.error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching top performers:', err);
      }
    };

    const validateDates = () => {
      startDateError.value = new Date(startDate.value) > new Date(endDate.value);

      if (!startDateError.value && !endDateError.value) {
        fetchTopPerformers();
      }
    };

    const resetDates = () => {
      startDate.value = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10);
      endDate.value = new Date().toISOString().slice(0, 10);
      validateDates()
    };

    const getColor = (index) => {
      const colors = [
        'purple-accent-4',
        //'blue-accent-4',
        //'green-accent-4',
        //'orange-accent-4',
        //'red-accent-4'
      ];
      return colors[index % colors.length];
    };

    const getIcon = (aggregationType) => {
      switch (aggregationType) {
        case 'Contacts Created':
          return 'mdi-account-outline';
        case 'Tasks Completed':
          return 'mdi-check-circle-outline';
        case 'Tasks Created':
          return 'mdi-playlist-edit';
        default:
          return 'mdi-account-outline';
      }
    };

    watch([aggregation], fetchTopPerformers);

    onMounted(() => {
      fetchTopPerformers();
    });

    return {
      presenterData,
      aggregation,
      aggregationOptions,
      startDate,
      endDate,
      resetDates,
      startDateError,
      endDateError,
      validateDates,
      fetchTopPerformers
    };
  }
};
</script>

<style scoped>
.v-list-item {
  border-left: 5px solid rgba(94, 94, 94, 0.268);
  border-bottom: 1px solid rgba(94, 94, 94, 0.268);
  border-top: 1px solid rgba(94, 94, 94, 0.268);
  border-right: 1px solid rgba(94, 94, 94, 0.268);
  transition: all 0.3s ease;
}
.v-list-item:hover {
  background-color: rgba(94, 94, 94, 0.268);
}
.v-chip {
  font-size: 0.875rem;
}
</style>
