<template>
  <div>
    <v-navigation-drawer
      class="no-scrollbar"
      :width="rail ? 80 : 320"
      v-model="drawer"
      :rail="rail"
      nav
      @update:model="onDrawerUpdate"
    >
      <v-list>
        <v-list-item
          :subtitle="userState.state.userProfile?.displayName"
          title="Global Precision Group"
          @click="toggleRail"
        >
          <template v-slot:prepend>
            <v-avatar color="purple">
              <span :class="rail ? 'text-caption' : 'text-h6'">{{ initials }}</span>
            </v-avatar>
          </template>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item value="dashboard" prepend-icon="mdi-chart-areaspline" @click="handleClick('/views/dashboard')">Dashboard</v-list-item>
        <v-list-item value="contacts" prepend-icon="mdi-contacts-outline" @click="handleClick('/views/contacts')">Contacts</v-list-item>
        <v-list-item value="task-history" prepend-icon="mdi-calendar-check" @click="handleClick('/views/task-history')">Tasks</v-list-item>
        <!--<v-list-item value="calendar" prepend-icon="mdi-calendar-month-outline" @click="handleClick('/views/calendar')">Calendar</v-list-item>-->
      </v-list>

      <template v-slot:append>
        <v-list density="compact" nav>
          <v-list-item value="settings" v-if="rail" @click="handleClick('/views/settings')">
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item>
          <v-btn v-else @click="handleClick('/views/settings')" prepend-icon="mdi-cog-outline" variant="text" block>
            Settings
          </v-btn>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-bottom-navigation v-if="!drawer" grow>
      <v-btn value="dashboard" @click="handleClick('/views/dashboard')" icon="mdi-chart-areaspline"></v-btn>
      <v-btn value="contacts" @click="handleClick('/views/contacts')" icon="mdi-contacts-outline"></v-btn>
      <v-btn value="task-history" @click="handleClick('/views/task-history')" icon="mdi-calendar-check"></v-btn>
      <v-btn value="settings" @click="handleClick('/views/settings')" icon="mdi-cog-outline"></v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { useUserState } from '@/services/userStore';

export default {
  name: 'NavigationPanel',
  props: {
    uncompletedTaskCount: Number,
  },
  data() {
    return {
      drawer: true,
      rail: false,
      activeItem: null,
      userState: useUserState(),
    };
  },
  computed: {
    initials() {
      if (this.userState.state.userProfile && this.userState.state.userProfile.displayName) {
        const nameParts = this.userState.state.userProfile.displayName.split(' ');
        let initials = nameParts[0][0];
        if (nameParts.length > 1) {
          initials += nameParts[nameParts.length - 1][0];
        }
        return initials;
      }
      return '';
    },
  },
  methods: {
    setActive(subItem) {
      if (this.activeItem !== subItem) {
        this.activeItem = subItem;
        this.$router.push(subItem.destination);
      }
    },
    handleClick(route) {
      this.$router.push(route);
    },
    toggleSettingsDialog() {
      this.$emit('showSettingsDialog');
    },
    logUserOut() {
      this.$emit('logUserOut');
    },
    onDrawerUpdate(value) {
      this.drawer = value;
    },
    toggleRail() {
      this.rail = !this.rail;
    },
    toggleTheme() {
      this.$emit('toggleTheme');
    }
  }
}
</script>

<style scoped>
.full-background {
  min-height: 100vh;
}
</style>
