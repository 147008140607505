<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Archive Contact</span>
    </template>

    <template v-slot:actions>
      <v-chip 
      class="ma-1 mr-2" 
      variant="tonal" 
      color="primary"
      label>
        <v-icon icon="mdi-label-outline" start></v-icon>
        {{ targetContact.contact_ID }}
      </v-chip>
    </template>

    <template v-slot:content>
      <v-row>
        <v-col cols="12" class="pt-4 pb-0">
          <p><strong>Contact Details</strong></p>
        </v-col>

        <v-col cols="12">
          <v-card class="py-4" variant="flat" color="grey-lighten-4" style="border: 1px solid #e0e0e0;">
            <v-row v-for="(field, index) in fields" :key="index" class="px-4">
              <v-col class="text-left" cols="6">
                <strong>{{ field.label }}:</strong>
              </v-col>
              <v-col class="text-right" cols="6">
                {{ targetContact[field.key] === null || targetContact[field.key] === "" ? 'unknown' : targetContact[field.key] }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-divider class="py-2 mx-3"></v-divider>

        <v-col cols="12" class="pt-0">
          <p>This contact will not be visible on queries or tasks.</p>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-switch
            v-model="hasConfirmed"
            color="primary"
            :label="hasConfirmed ? 'Archive Contact ' : 'Keep Contact'"
            inset
          ></v-switch>
        </v-col>
      </v-row>
    </template>

    <template v-slot:footer>
      <v-btn 
        variant="text" 
        @click="closeDialog"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        append-icon="mdi-archive-outline"
        variant="tonal"
        :loading="isArchiveBtnLoading"
        :disabled="!hasConfirmed"
        @click="archiveContact"
      >Archive Contact</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';

export default {
  name: 'ArchiveContactDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    targetContact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      hasConfirmed: false,
      isArchiveBtnLoading: false,
      fields: [
        { label: 'Company', key: 'company_Name' },
        { label: 'Contact Name', key: 'full_Name' },
        { label: 'Title', key: 'title' },
        { label: 'Account Manager', key: 'account_Holder' },
        { label: 'Open Task Count', key: 'task_Count' }
      ]
    };
  },
  methods: {
    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    async archiveContact() {
      this.isArchiveBtnLoading = true;

      try {
        await api.put(`/Contact/ArchiveContact?contactId=${this.targetContact.contact_ID}`);
        await new Promise(resolve => setTimeout(resolve, 500));

        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Archived Contact!', this.targetContact.first_Name + ' ' + this.targetContact.last_Name + ' has been Archived.');
        this.refreshParentCollection();
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Archive Contact.', 'An error occurred while attempting to archive contact');
      } finally {
        this.isArchiveBtnLoading = false;
      }
    },
    resetForm() {
      this.hasConfirmed = false;
    },
    refreshParentCollection() {
      this.$emit('refresh-contacts');
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000
      });
    }
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      }
    }
  }
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
