<template>
  <v-container>
    <v-select
      density="compact"
      variant="outlined"
      v-model="aggregation"
      :items="aggregationOptions"
      label="Aggregation Property"
    ></v-select>

    <div class="chart-container">
      <canvas height="300px" id="taskChart"></canvas>
    </div>
  </v-container>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import Chart from 'chart.js/auto';
import api from '@/services/api';

export default {
  name: 'TasksCompletedChart',
  setup() {
    const aggregation = ref('Daily');
    const aggregationOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly'];
    const rawData = ref([]);
    let chartInstance = null;

    const fetchContactData = async () => {
      try {
        const response = await api.get('/dashboard/GetTasksCompletedDates');
        if (response && response.data && Array.isArray(response.data)) {
          rawData.value = response.data;
          aggregateAndRenderData();
        } else {
          console.error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching task completed dates:', err);
      }
    };

    const aggregateAndRenderData = () => {
      const { labels, data } = aggregateData(rawData.value, aggregation.value);
      renderChart(labels, data);
    };

    const getTimeRange = (minDate, maxDate, period, maxPoints) => {
      const currentDate = new Date(maxDate);
      const range = [];

      while (range.length < maxPoints) {
        range.unshift(new Date(currentDate));
        switch (period) {
          case 'Daily':
            currentDate.setDate(currentDate.getDate() - 1);
            break;
          case 'Weekly':
            currentDate.setDate(currentDate.getDate() - 7);
            break;
          case 'Monthly':
            currentDate.setMonth(currentDate.getMonth() - 1);
            break;
          case 'Quarterly':
            currentDate.setMonth(currentDate.getMonth() - 3);
            break;
        }
        if (currentDate < minDate) break;
      }
      return range;
    };

    const aggregateData = (dates, period) => {
      const counts = {};
      const formattedDates = dates.map(dateStr => new Date(dateStr));
      const minDate = new Date(Math.min(...formattedDates));
      const maxDate = new Date(Math.max(...formattedDates));
      const maxPoints = period === 'Daily' ? 10 : period === 'Weekly' ? 10 : period === 'Monthly' ? 12 : 8;
      const timeRange = getTimeRange(minDate, maxDate, period, maxPoints);

      timeRange.forEach(date => {
        let label;

        switch (period) {
          case 'Daily':
            label = date.toISOString().split('T')[0];
            break;
          case 'Weekly': {
            const weekStart = new Date(date);
            weekStart.setDate(date.getDate() - date.getDay());
            label = weekStart.toISOString().split('T')[0];
            break;
          }
          case 'Monthly':
            label = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            break;
          case 'Quarterly': {
            const quarter = Math.floor(date.getMonth() / 3) + 1;
            label = `${date.getFullYear()} Q${quarter}`;
            break;
          }
          default:
            label = date.toISOString().split('T')[0];
        }

        if (!counts[label]) {
          counts[label] = 0;
        }
      });

      formattedDates.forEach(date => {
        let label;
        switch (period) {
          case 'Daily':
            label = date.toISOString().split('T')[0];
            break;
          case 'Weekly': {
            const weekStart = new Date(date);
            weekStart.setDate(date.getDate() - date.getDay());
            label = weekStart.toISOString().split('T')[0];
            break;
          }
          case 'Monthly':
            label = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            break;
          case 'Quarterly': {
            const quarter = Math.floor(date.getMonth() / 3) + 1;
            label = `${date.getFullYear()} Q${quarter}`;
            break;
          }
          default:
            label = date.toISOString().split('T')[0];
        }

        if (counts[label] !== undefined) {
          counts[label]++;
        }
      });

      const labels = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
      const data = labels.map(label => counts[label]);

      return { labels, data };
    };

    const renderChart = (labels, data) => {
      const ctx = document.getElementById('taskChart').getContext('2d');
      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            barPercentage: 0.7,
            label: 'Tasks Completed',
            data: data,
            backgroundColor: 'rgba(150, 0, 255, 0.3)',
            borderColor: 'rgba(150, 0, 255, 1)',
            borderWidth: 1
          }]
        },
        options: {
          borderRadius: 3,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            },
            x: {
              ticks: {
                maxTicksLimit: 10 // Limiting the number of ticks on the x-axis
              }
            }
          }
        }
      });
    };

    watch(aggregation, aggregateAndRenderData);

    onMounted(() => {
      fetchContactData();
    });

    return {
      aggregation,
      aggregationOptions,
      rawData
    };
  }
};
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 300px;
}
</style>
