<template>
  <div>
    <view-container :viewHeader="'Management'">
      <v-card class="pa-6" elevation="2">
        <v-row>
          <v-col cols="12">
            <v-subheader class="mb-1">User Information</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="userProfile.displayName"
              label="Display Name"
              density="compact"
              readonly
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="userProfile.email">
            <v-text-field
              v-model="userProfile.email"
              label="Email"
              variant="outlined"
              density="compact"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-subheader class="mb-1">Settings</v-subheader>
            <v-divider></v-divider>
          </v-col>

          <v-col class="py-0" cols="12">
            <v-switch
              color="purple"
              v-model="miscSettings.loadBuffer"
              label="Enable Load Buffer"
              @change="toggleLoadBuffer"
            ></v-switch>
          </v-col>

          <v-col class="py-0" cols="12">
            <v-switch
              color="purple"
              v-model="miscSettings.confirmations"
              label="Enable Dialog Confirmations"
              @change="toggleDialogConfirmations"
            ></v-switch>
          </v-col>
        </v-row>

        <v-divider class="my-4"></v-divider>
        <v-row justify="center">
          <v-col cols="12">
            <v-btn @click="logUserOut" variant="tonal" color="error" dark large block>
              Logout
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </view-container>
  </div>
</template>

<script setup>
import { useTheme } from 'vuetify';
import { useUserState } from '@/services/userStore';
import ViewContainer from '../layouts/ViewContainer';
import { ref, computed, onMounted } from 'vue';

const userState = useUserState();
const miscSettings = ref({
  darkMode: false,
  loadBuffer: true,
  notifications: true,
  confirmations: true,
});
const theme = useTheme();

const userProfile = computed(() => userState.state.userProfile || {});

onMounted(() => {
  miscSettings.value.darkMode = theme.global.current.value.dark;
});


function toggleLoadBuffer() {

}

function toggleDialogConfirmations() {

}

function logUserOut() {
  userState.clearUserProfile();
  localStorage.removeItem('token');
  window.location.href = '/'; // Assuming you want to redirect to the home page
}
</script>

<style scoped>
</style>
