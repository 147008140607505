<template>
  <v-app>
    <router-view v-slot="{ Component, route }">
      <span v-if="!route.meta.hideNav">
        <NavigationPanel
          :uncompletedTaskCount="getUncompletedTasksCount"
          @logUserOut="logUserOut"
          @toggleTheme="toggleTheme"
        ></NavigationPanel>
      </span>

      <v-main>
        <v-sheet class="bg-lightBackground full-background">
          <keep-alive>
            <component :is="Component" @toggleTheme="toggleTheme"></component>
          </keep-alive>
        </v-sheet>
      </v-main>
    </router-view>
  </v-app>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/services/api';
import msalInstance from './services/authConfig';
import NavigationPanel from './components/navigation/NavigationPanel';
import { useUserState } from '@/services/userStore';
import { useTheme } from 'vuetify';

const theme = useTheme();
const userState = useUserState();
const router = useRouter();
const hasAuthenticated = ref(false);

const isDarkMode = ref(theme.global.current.value.dark);
const isLoggingOut = ref(false);

function toggleTheme() {
  isDarkMode.value = !isDarkMode.value;
  theme.global.name.value = isDarkMode.value ? 'dark' : 'light';
  localStorage.setItem('darkMode', JSON.stringify(isDarkMode.value));
}

onMounted(() => {
  const savedDarkMode = localStorage.getItem('darkMode');
  if (savedDarkMode !== null) {
    isDarkMode.value = JSON.parse(savedDarkMode);
    theme.global.name.value = isDarkMode.value ? 'dark' : 'light';
  }

  const token = localStorage.getItem('token');
  if (token && !isLoggingOut.value) {
    validateToken(token);
  }
});

async function validateToken(token) {
  try {
    const response = await api.get('/authentication/ValidateViaAzure', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      userState.state.hasAuthenticated = true;
      const userProfile = await getUserProfile();
      if (userProfile) {
        userState.setUserProfile(userProfile);
        hasAuthenticated.value = true;
      }
    } else {
      userState.state.hasAuthenticated = false;
      router.push({ path: '/' });
    }
  } catch (error) {
    console.error('Error during token validation:', error);
    userState.state.hasAuthenticated = false;
    router.push({ path: '/' });
  }
}

async function getUserProfile() {
  try {
    const response = await api.get('/authentication/GetUser');
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Failed to get user');
      return null;
    }
  } catch (error) {
    console.error('Error while getting user', error);
    return null;
  }
}

function logUserOut() {
  isLoggingOut.value = true;
  msalInstance.logoutPopup().then(() => {
    userState.clearUserProfile();
    localStorage.removeItem('token');
    router.push({ name: 'Login' });
    setTimeout(() => {
      isLoggingOut.value = false;
    }, 1000); // Small delay to ensure logout process completes
  }).catch(error => {
    console.error('Error during logout:', error);
  });
}
</script>

<style scoped>
.full-background {
  min-height: 100vh;
}
</style>
