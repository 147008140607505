<template>

  <DialogBase 
    :isDialogVisible="dialogOpen"
  >
    <template v-slot:header>
      <span>{{targetContact.full_Name}}</span>
    </template>

    
    <template v-slot:actions>
      <v-chip 
      class="ma-1 mr-2" 
      variant="tonal" 
      color="primary"
      label>
        <v-icon icon="mdi-label-outline" start></v-icon>
        {{ targetContact.contact_ID }}
      </v-chip>
    </template>

    <template v-slot:content>
      <v-tabs class="mb-3" v-model="tab" bg-color="transparent" color="primary" grow>
        <v-tab value="view-tasks">View Tasks</v-tab>
        <v-tab v-if="!this.$vuetify.display.smAndDown" :disabled="tab !== 'task-details'" value="task-details">Task Details</v-tab>
        <v-tab value="add-task">Add Task</v-tab>
        <v-tab value="edit-task">Edit Task</v-tab>
      </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="view-tasks">
          <v-card class="ma-0 pa-0">
            <div v-if="tasks.length === 0">
              <v-row class="justify-center mt-8">
                All tasks are completed!
              </v-row>
              <v-row class="justify-center mt-8">
                <v-btn variant="tonal" color="primary" @click="tab = 'add-task'">Add Task</v-btn>
              </v-row>
            </div>
            <v-card-text class="pt-0">
              <v-progress-linear indeterminate="true" color="primary" v-if="isCompletingTaskLoading"></v-progress-linear>
              <!-- View Tasks Content -->
              <v-list-subheader v-if="tasks.length !== 0">Action Required</v-list-subheader>
              <v-list class="ma-0 py-0 px-2" max-height="240">
                <v-list-item class="px-0" v-for="task in tasks" :key="task.id">
                  <v-alert
                    color="primary"
                    density="comfortable"
                    border="start"
                    border-color="blue accent-4"
                    variant="tonal"
                    transition="slide-x-transition"
                  >
                    <v-row align="center" no-gutters>
                      <v-col class="align-center">
                        <v-icon color="primary" class="mr-1">{{ task.icon }}</v-icon>
                        {{ task.type }}
                      </v-col>
                      <v-col class="text-right">
                        <v-btn
                          class="mr-2"
                          size="small"
                          density="comfortable"
                          icon="mdi-help"
                          variant="tonal"
                          color="blue-darken-2"
                          @click="viewTaskDetails(task)"
                        ></v-btn>
                        <v-btn
                          size="small"
                          density="comfortable"
                          icon="mdi-check"
                          variant="tonal"
                          color="blue-darken-2"
                          @click="completeTask(task.contact_Task_ID)"
                          text="Mark Completed"
                        ></v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tabs-window-item>

        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="task-details">
            <v-card class="ma-0 pa-0 mt-1" variant="flat" color="grey-lighten-4" style="border: 1px solid #e0e0e0;">
              <v-card-title class="text-subtitle-1">
                <v-row class="mb-1 mt-1">
                  <v-icon class="ml-2 mr-2">{{ selectedTaskDetails.icon ?? 'mdi-alert-circle' }}</v-icon>
                  {{ selectedTaskDetails.type ?? 'Unknown Type' }}
                  <v-spacer></v-spacer>
                  <v-btn size="small" class="mr-2" variant="outlined" @click="completeTask(selectedTaskDetails.contact_Task_ID)">Complete Task</v-btn>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-card class="py-4" variant="flat" color="grey-lighten-4" style="border: 1px solid #e0e0e0;">
                      <v-row v-for="(field, index) in fields" :key="index" class="px-4">
                        <v-col class="text-left" cols="6">
                          <strong>{{ field.label }}:</strong>
                        </v-col>
                        <v-col class="text-right" cols="6">
                          {{ field.key === 'action_Date' || field.key === 'date_Created' ? formatDate(selectedTaskDetails[field.key]) : (selectedTaskDetails[field.key] === null || selectedTaskDetails[field.key] === "" ? 'Not Specified' : selectedTaskDetails[field.key]) }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tabs-window-item>
        </v-tabs-window>

        <v-tabs-window-item value="add-task">
          <v-card class="ma-0 pa-0">
            <v-card-text>
              <!-- Add Task Content -->
              <v-form ref="addTaskForm">
                <v-select
                  :items="taskTypes"
                  v-model="newTask.type_ID"
                  item-title="type"
                  item-value="type_ID"
                  variant="outlined"
                  density="compact"
                  label="Task Type"
                  :rules="[v => !!v || 'Task type is required']"
                ></v-select>
                <v-textarea
                  class="pb-2"
                  variant="outlined"
                  density="compact"
                  v-model="newTask.description"
                  label="Task Description"
                  :rules="[v => !!v || 'Description is required']"
                ></v-textarea>
                <v-text-field
                  variant="outlined"
                  density="compact"
                  v-model="newTask.action_Date"
                  label="Action Date"
                  type="date"
                ></v-text-field>
                <v-btn
                  color="primary"
                  :variant="isAddBtnDisabled ? 'outlined' : 'tonal'"
                  :disabled="isAddBtnDisabled"
                  :loading="isAddBtnLoading"
                  @click="addTask"
                  block
                >Add Task</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tabs-window-item>

        <v-tabs-window-item value="edit-task">
          <v-card class="ma-0 pa-0">
            <v-card-text>
              <!-- Edit Task Content -->
              <v-form ref="editTaskForm">
                <v-select
                  variant="outlined"
                  density="compact"
                  v-model="selectedTask"
                  :items="tasks"
                  item-title="type"
                  item-value="contact_Task_ID"
                  label="Select Task"
                  :rules="[v => !!v || 'Task selection is required']"
                ></v-select>
                <v-select
                  :disabled="!selectedTask"
                  :items="taskTypes"
                  v-model="newEdittedTask.type_ID"
                  item-title="type"
                  item-value="type_ID"
                  variant="outlined"
                  density="compact"
                  label="Task Type"
                  :rules="[v => !!v || 'Task type is required']"
                ></v-select>
                <v-textarea
                  :disabled="!selectedTask"
                  variant="outlined"
                  density="compact"
                  v-model="newEdittedTask.description"
                  label="Task Description"
                  :rules="[v => !!v || 'Description is required']"
                ></v-textarea>
                <v-row>
                  <v-col>
                    <v-text-field
                      :disabled="!selectedTask"
                      variant="outlined"
                      density="compact"
                      v-model="newEdittedTask.action_Date"
                      label="New Due Date"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :disabled="!selectedTask || !oldSelectedActionDate"
                      readonly="true"
                      variant="outlined"
                      density="compact"
                      v-model="formattedOldSelectedActionDate"
                      label="Previous Due Date"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-btn
                  color="primary"
                  :variant="isEditBtnDisabled ? 'outlined' : 'tonal'"
                  :loading="isEditBtnLoading"
                  :disabled="isEditBtnDisabled"
                  @click="editTask"
                  block
                >Save Changes</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tabs-window-item>
      </v-tabs-window>
    </template>

    <template v-slot:footer>
      <v-btn text="Cancel" variant="text" @click="closeDialog">Cancel</v-btn>
    </template>
  </DialogBase>

</template>

<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';

export default {
  name: 'ContactTasksDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    targetContact: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // task list
      tasks: [], 
      taskTypes: [],
      isCompletingTaskLoading: false,
      isTasksLoading: true,

      // navigation elements
      tab: 'view-tasks',
      showDetailTab: false,
      items: [
        { title: 'View Tasks', value: 'view-tasks' },
        { title: 'Add Task', value: 'add-task' },
        { title: 'Edit Task', value: 'edit-task' },
        { title: 'Task Details', value: 'task-details' },
      ],

      fields: [
        { label: 'Description', key: 'description' },
        { label: 'Assigned To', key: 'contact_Name' },
        { label: 'Due Date', key: 'action_Date' },
        { label: 'Created By', key: 'created_By' },
        { label: 'Date Created', key: 'date_Created' }
      ],

      // new task validation
      newTask: {
        type_ID: null,
        description: '',
        action_Date: null,
      },
      isAddBtnLoading: false,

      // edit task validation
      newEdittedTask: {
        id: null,
        type_ID: null,
        description: '',
        action_Date: null,
      },
      oldSelectedActionDate: null,
      selectedTask: null,
      selectedTaskDetails: {},
      isEditBtnLoading: false,
    };
  },
  mounted() {
    this.getContactTasks();
    this.getTaskTypes();
  },
  computed: {
    isEditBtnDisabled() {
      return !this.newEdittedTask.type_ID || this.newEdittedTask.description.length < 3 || !this.selectedTask;
    },
    isAddBtnDisabled() {
      return !this.newTask.type_ID || this.newTask.description.length < 3;
    },
    formattedOldSelectedActionDate() {
      if (!this.oldSelectedActionDate) return '';
      const date = new Date(this.oldSelectedActionDate);
      return date.toLocaleDateString(); // Format the date as a localized date string
    },
    dialogOpen: {
      get() {
        this.getContactTasks();
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:is-dialog-open', value);
      },
    },
  },
  watch: {
    selectedTask(newTaskId) {
      const selected = this.tasks.find(task => task.contact_Task_ID === newTaskId);
      if (selected) {
        this.newEdittedTask = {
          id: selected.contact_Task_ID,
          type_ID: selected.type_ID,
          description: selected.description,
          action_Date: selected.action_Date,
        };
        //readonly old date
        this.oldSelectedActionDate = selected.action_Date
      }
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'Not Specified';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    async completeTask(id) {
      this.isCompletingTaskLoading = true;
      try {
        const response = await api.put(`/ContactTask/UpdateContactTaskToCompleted?taskId=${id}`);
        await new Promise(resolve => setTimeout(resolve, 500));
        console.log(response.data);
        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Completed Task!', 'Completed task related to ' + this.targetContact.first_Name + ' ' + this.targetContact.last_Name) + '.';
        await this.getContactTasks();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Complete Task', 'An error occured while attempting to complete task.');
      } finally {
        this.refreshParentCollection();
        this.tab = "view-tasks"
        this.isCompletingTaskLoading = false;
      }
    },
    async getContactTasks() {
      console.log('getting contact tasks');
      try {
        this.isTasksLoading = true;
        const response = await api.get(`/ContactTask/GetTasksForContactAsync?contactId=${this.targetContact.contact_ID}`);
        if (response.status === 200) {
          this.tasks = response.data;
        } else if (response.status === 429) {
          this.tooManyRequestsDialog = true;
        } else {
          console.log('internal error');
        }
      } catch (error) {
        console.error(error);
      }
      this.isTasksLoading = false;
    },
    closeDialog() {
      this.resetForm();
      this.tab = "view-tasks"
      this.dialogOpen = false;
      this.tasks = [];
    },
    resetForm() {
      this.newTask = { type_ID: null, description: '', action_Date: null };
      this.selectedTask = null;
      this.newEdittedTask = { id: null, type_ID: null, description: '', action_Date: null };
    },
    async addTask() {
      this.isAddBtnLoading = true;
      const contactTask = {
        Type_ID: this.newTask.type_ID,
        Description: this.newTask.description,
        Contact_ID: this.targetContact.contact_ID,
        Action_Date: this.newTask.action_Date,
      };

      console.log(contactTask)

      try {
        const response = await api.post('/ContactTask/AddContactTask', contactTask);
        await new Promise(resolve => setTimeout(resolve, 500));
        console.log(response.data);
        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Created Task!', 'New task has been created.');
        await this.getContactTasks();
        this.tab = 'view-tasks';
        this.refreshParentCollection();
        this.resetForm();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Create Task', 'An error occured while attempting to create new task.');
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    async editTask() {
      this.isEditBtnLoading = true;
      const editedContact = {
        Contact_Task_ID: this.selectedTask,
        Type_ID: this.newEdittedTask.type_ID,
        Description: this.newEdittedTask.description,
        Action_Date: this.newEdittedTask.action_Date,
      };
      try {
        const response = await api.put('/ContactTask/UpdateContactTask', editedContact);
        await new Promise(resolve => setTimeout(resolve, 500));
        console.log(response.data);
        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Updated Task!', 'Task ' + editedContact.Contact_Task_ID + ' has been updated.');
        await this.getContactTasks();
        this.resetForm();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Update Task', 'An error occured while attempting to update task.');
      } finally {
        this.tab = 'view-tasks';
        this.isEditBtnLoading = false;
      }
    },
    async getTaskTypes() {
      try {
        const response = await api.get('/ContactTask/GetContactTaskTypes');
        if (response.status === 200) {
          this.taskTypes = response.data;
        } else {
          console.log('internal error');
        }
      } catch (error) {
        console.error(error);
      }
    },
    viewTaskDetails(task) {
      this.selectedTaskDetails = task;
      this.showDetailTab = true;
      this.tab = 'task-details';
    },
    backToTasks() {
      this.showDetailTab = false;
      this.tab = 'view-tasks';
    },
    refreshParentCollection() {
      this.$emit('refresh-contacts');
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
};
</script>
