<template>
  <div>
    <view-container :viewHeader="'Management'">
      <v-card class="pa-2 pt-0">
        <v-calendar
          ref="calendar"
          v-model="value"
          :events="events"
          :view-mode="type"
          :weekdays="weekday.value"
        ></v-calendar>
      </v-card>
    </view-container>
  </div>
</template>

<script>
import { useDate } from 'vuetify';
import api from '@/services/api';
import ViewContainer from '../layouts/ViewContainer';

export default {
  name: 'CalendarView',
  components: {
    ViewContainer,
  },
  data: () => ({
    type: 'month',
    weekday: { title: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
    value: [new Date()],
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    titles: ['Task Due'],
  }),
  mounted() {
    const adapter = useDate();
    this.getEvents({
      start: adapter.startOfDay(adapter.startOfMonth(new Date())),
      end: adapter.endOfDay(adapter.endOfMonth(new Date())),
    });
  },
  methods: {
    async getEvents() {
      try {
        const response = await api.get('/ContactTask/GetUncompletedActionDates');
        console.log(response.data)
        if (response && response.data && Array.isArray(response.data)) {
          const dates = response.data.map(dateStr => new Date(dateStr.action_Date));
          this.populateEvents(dates);
        } else {
          console.error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching contact action dates:', err);
      }
    },
    populateEvents(dates) {
      const events = dates.map(date => ({
        title: this.titles[this.rnd(0, this.titles.length - 1)],
        name: 'Task',
        start: date,
        end: date,
        color: this.colors[this.rnd(0, this.colors.length - 1)],
      }));
      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
}
</script>

<style scoped>

</style>
