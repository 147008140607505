<template>
  <div>
    <v-row dense>
      <v-col cols="12" class="py-0" md="1"></v-col>

      <v-col cols="12" md="12">
        <v-card class="mb-3 mt-0" elevation="1">
          <v-row class="pl-3 py-3 text-center align-center justify-center">
            <bread-crumbs class="pl-3 py-3"></bread-crumbs>
            <v-spacer></v-spacer>
            <p class="align-center justify-center text-center text-grey-lighten-1">V1.0.0</p>

            <v-list-item value="theme" class="ml-4 px-2" @click="toggleTheme">
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item>

            <v-list-item value="settings" class="ml-1 px-2 mr-6" @click="settingsBtnClicked">
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item>
          </v-row>
        </v-card>

        <p class="text-h5 mt-1">
          <!--{{this.viewHeader}}-->
        </p>
        <slot></slot>
      </v-col>

      <v-col cols="12" md="1"></v-col>
    </v-row>

    <v-snackbar
      elevation="0"
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      @input="handleSnackbarClose"
    >
      <template v-if="snackbar.icon">
        <v-icon left>{{ snackbar.icon }}</v-icon>
      </template>
      {{ snackbar.title }}
      <template v-if="snackbar.description">
        <v-divider class="my-2"></v-divider>
        <div>{{ snackbar.description }}</div>
      </template>
      <template v-slot:actions="{ attrs }">
        <v-btn class="ml-4" v-bind="attrs" @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import BreadCrumbs from '../../components/navigation/BreadCrumbs';
import eventBus from '../../services/eventBus';
import VueCookies from 'vue-cookies';

export default {
  name: 'ViewContainer',
  components: {
    BreadCrumbs,
  },
  props: {
    viewHeader: String,
  },
  data() {
    return {
      drawer: true,
      rail: false,
      snackbar: {
        show: false,
        timeout: 5000,
        color: 'success',
        icon: '',
        title: '',
        description: '',
      },
      notifications: [], // List of all notifications for the notification bar
    };
  },
  methods: {
    settingsBtnClicked() {
      this.$router.push('/views/settings');
    },
    async showSnackbar({ icon, title, description, color, timeout }) {
      if (this.snackbar.show) {
        this.snackbar.show = false;
        await new Promise(resolve => setTimeout(resolve, 300)); // Slight delay between snackbars
      }
      this.snackbar.icon = icon || '';
      this.snackbar.title = title || '';
      this.snackbar.description = description || '';
      this.snackbar.color = color || 'success';
      this.snackbar.timeout = timeout || 5000;
      this.snackbar.show = true;

      this.notifications.push({ icon, title, description, color, timeout });
    },
    handleSnackbarClose() {
      this.snackbar.show = false;
    },
    async closeSnackbar() {
      this.snackbar.show = false;
      await new Promise(resolve => setTimeout(resolve, 300)); // Ensure the snackbar is fully closed
    },
    toggleTheme() {
      const currentTheme = VueCookies.get('theme') || 'light';
      const newTheme = currentTheme === 'light' ? 'dark' : 'light';
      this.$vuetify.theme.global.name = newTheme;
      VueCookies.set('theme', newTheme, '7d'); // Set the theme cookie for 7 days
    },
    setInitialTheme() {
      const savedTheme = VueCookies.get('theme') || 'light';
      this.$vuetify.theme.global.name = savedTheme;
    },
  },
  mounted() {
    eventBus.on('show-snackbar', this.showSnackbar);
    this.setInitialTheme();
  },
  beforeUnmount() {
    eventBus.off('show-snackbar', this.showSnackbar);
  },
};
</script>
