<template>

  <DialogBase 
    :isDialogVisible="dialogOpen"
  >
    <template v-slot:header>
      <span>Edit Contact Task</span>
    </template>

    <template v-slot:actions>
      <v-chip 
      class="ma-1 mr-2" 
      variant="tonal" 
      color="primary"
      label>
        <v-icon icon="mdi-label-outline" start></v-icon>
        {{ targetTask.contact_Task_ID }}
      </v-chip>
    </template>

    <template v-slot:content>
      <v-form ref="editTaskForm" v-model="valid" lazy-validation>
        <v-select
          class="mt-2"
          :items="taskTypes"
          v-model="newEdittedTask.type_ID"
          item-title="type"
          item-value="type_ID"
          variant="outlined"
          density="compact"
          label="Task Type"
          :rules="[v => !!v || 'Task type is required']"
        ></v-select>
        <v-textarea
          class="pb-3"
          variant="outlined"
          density="compact"
          v-model="newEdittedTask.description"
          label="Task Description"
          :rules="[v => !!v || 'Description is required']"
        ></v-textarea>
        <v-row>
          <v-col>
            <v-text-field
              variant="outlined"
              density="compact"
              v-model="newEdittedTask.action_Date"
              label="New Due Date"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :disabled="!oldSelectedActionDate"
              readonly="true"
              variant="outlined"
              density="compact"
              v-model="formattedOldSelectedActionDate"
              label="Previous Due Date"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:footer>
      <v-btn 
        text="Cancel"
        variant="text"
        @click="closeDialog"
      ></v-btn>
      <v-btn
        text="Edit Contact"
        color="primary"
        prepend-icon="mdi-pencil-outline"
        variant="tonal"
        :loading="isEditBtnLoading"
        :disabled="!valid"
        @click="editTask"
      ></v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api';
import DialogBase from './DialogBase.vue';

export default {
  name: 'EditTaskDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    targetTask: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      valid: false,
      isEditBtnLoading: false,
      taskTypes: [],
      newEdittedTask: {
        id: null,
        type_ID: null,
        description: '',
        action_Date: null,
      },
      oldSelectedActionDate: null,
    };
  },
  mounted() {
    this.getTaskTypes();
  },
  watch: {
    isDialogOpen: {
      immediate: true,
      handler(val) {
        if (val) {
          this.populateFields();
        }
      },
    },
  },
  computed: {
    formattedOldSelectedActionDate() {
      if (!this.oldSelectedActionDate) return '';
      const date = new Date(this.oldSelectedActionDate);
      return date.toLocaleDateString(); // Format the date as a localized date string
    },
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      },
    },
  },
  methods: {
    populateFields() {
      if (this.targetTask) {
        this.newEdittedTask = { ...this.targetTask };
        this.oldSelectedActionDate = this.targetTask.action_Date;
      }
    },
    async getTaskTypes() {
      try {
        const response = await api.get('/ContactTask/GetContactTaskTypes');
        if (response.status === 200) {
          this.taskTypes = response.data;
        } else {
          console.log('internal error');
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeDialog() {
      this.resetForm();
      this.dialogOpen = false;
    },
    resetForm() {
      this.newEdittedTask = { id: null, type_ID: null, description: '', action_Date: null };
      this.oldSelectedActionDate = null;
      this.valid = false;
    },
    async editTask() {
      this.isEditBtnLoading = true;
      const editedTask = {
        Contact_Task_ID: this.targetTask.contact_Task_ID,
        Type_ID: this.newEdittedTask.type_ID,
        Description: this.newEdittedTask.description,
        Action_Date: this.newEdittedTask.action_Date,
      };
      try {
        const response = await api.put('/ContactTask/UpdateContactTask', editedTask);
        await new Promise(resolve => setTimeout(resolve, 500));
        console.log(response.data);
        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Updated Task!', 'Task ' + this.targetTask.contact_Task_ID + ' has been updated.');
        this.$emit('refresh-tasks')
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Update Task.', 'An error occured while attempting to update task.');
      } finally {
        this.isEditBtnLoading = false;
      }
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
      });
    },
    refreshParentCollection() {
      this.$emit('refresh-contacts');
    },
  },
};
</script>
