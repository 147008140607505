<template>
  <v-container>
    <div class="chart-container">
      <canvas height="350px" id="contactTypeChart"></canvas>
    </div>

  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import Chart from 'chart.js/auto';
import api from '@/services/api';

export default {
  name: 'ContactTypesChart',
  setup() {
    const rawData = ref([]);
    let chartInstance = null;

    const fetchContactTypeData = async () => {
      try {
        const response = await api.get('/dashboard/GetContactCountByType');
        if (response && response.data && Array.isArray(response.data)) {
          rawData.value = response.data;
          renderChart();
        } else {
          console.error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching contact count by type:', err);
      }
    };

    const renderChart = () => {
      const { labels, data } = formatData(rawData.value);

      if (chartInstance) {
        chartInstance.destroy();
      }

      const ctx = document.getElementById('contactTypeChart').getContext('2d');
      chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            barPercentage: 0.8,
            label: 'Contacts by Type',
            data: data,
            backgroundColor: 'rgba(150, 0, 255, 0.3)',
            borderColor: 'rgba(150, 0, 255, 1)',
            borderWidth: 1
          }]
        },
        options: {
          borderRadius: 3,
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    };

    const formatData = (data) => {
      const labels = data.map(item => item.key);
      const values = data.map(item => item.value);
      return { labels, data: values };
    };

    onMounted(() => {
      fetchContactTypeData();
    });

    return {
      rawData
    };
  }
};
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 350px;
}
</style>
