<template>
  <v-container>
    <div class="chart-container">
      <canvas height="310px" id="TaskStatusChart"></canvas>
    </div>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import Chart from 'chart.js/auto';
import api from '@/services/api';

export default {
  name: 'TaskStatusChart',
  setup() {
    const rawData = ref([]);
    let chartInstance = null;

    const fetchTaskStatusData = async () => {
      try {
        const response = await api.get('/dashboard/GetTaskStatusCounts');
        if (response && response.data) {
          rawData.value = response.data;
          renderChart();
        } else {
          console.error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching task status counts:', err);
      }
    };

    const renderChart = () => {
      const { labels, data } = formatData(rawData.value);

      if (chartInstance) {
        chartInstance.destroy();
      }

      const ctx = document.getElementById('TaskStatusChart').getContext('2d');
      chartInstance = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: 'Task Status',
            data: data,
            backgroundColor: [
              'rgba(150, 0, 255, 0.7)',
              'rgba(150, 0, 255, 0.4)',
              'rgba(150, 0, 255, 0.1)'
            ],
            borderColor: [
              'rgba(150, 0, 255, 0.8)',
              'rgba(150, 0, 255, 0.8)',
              'rgba(150, 0, 255, 0.8)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          hoverOffset: 10,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            }
          },
          layout: {
            padding: {
              bottom: 50
            }
          }
        }
      });
    };

    const formatData = (data) => {
      const labels = ['Past Due Date', 'Upcoming Due Date', 'No Immediate Due Date'];
      const values = [data.pastActionDate, data.upcomingActionDate, data.noImmediateActionDate];
      return { labels, data: values };
    };

    onMounted(() => {
      fetchTaskStatusData();
    });

    return {
      rawData
    };
  }
};
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 380px;
}
</style>
