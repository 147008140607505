<template>
  <div>
    <ViewContainer>
      <v-row dense>
        <v-col class="pa-0" cols="12" md="6">
          <v-card class="ma-2 pa-3" height="450">
            <p>Contacts Created Count</p>
            <v-divider></v-divider>
          
            <contact-additions-chart></contact-additions-chart>
            
          </v-card>
        </v-col>

        <v-col class="pa-0" cols="12" md="6">
          <v-card class="ma-2 pa-3" height="450">
            <p>Tasks Completed Count</p>
            <v-divider></v-divider>

              <tasks-completed-chart></tasks-completed-chart>
   
          </v-card>
        </v-col>

        <v-col class="pa-0" cols="12" md="4">
          <v-card class="ma-2 pa-3" height="450">
            <p>Contact Type Count</p>
            <v-divider></v-divider>
            <v-card-text class="d-flex align-center justify-center">
              <contact-types-chart></contact-types-chart>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="pa-0" cols="12" md="4">
          <v-card class="ma-2 pa-3" height="450">
            <p>Task Statuses</p>
            <v-divider></v-divider>
            <task-status-chart></task-status-chart>
          </v-card>
        </v-col>

        <v-col class="pa-0" cols="12" md="4">
          <v-card class="ma-2 pa-3" height="450">
            <p>Top Contributors</p>
            <v-divider></v-divider>

            <top-contributors-chart></top-contributors-chart>

          </v-card>
        </v-col>
      </v-row>  
    </ViewContainer>
  </div>
</template>

<script>
import ViewContainer from '../layouts/ViewContainer.vue';
import ContactAdditionsChart from '../../components/charts/ContactAdditionsChart';
import ContactTypesChart from '../../components/charts/ContactTypesChart';
import TopContributorsChart from '../../components/charts/TopContributorsChart';
import TasksCompletedChart from '../../components/charts/TasksCompletedChart';
import TaskStatusChart from '../../components/charts/TaskStatusChart';
import api from '@/services/api';

export default {
  name: 'HomeView',
  components: {
    ViewContainer,
    ContactAdditionsChart,
    ContactTypesChart,
    TasksCompletedChart,
    TopContributorsChart,
    TaskStatusChart
  },
  data() {
    return {
      recentContacts: [],
      topContributors: [],
    };
  },
  mounted() {
    this.fetchDashboardData();
  },
  methods: {
    async fetchDashboardData() {

    },
    async getTopContributors() {
      try {
        const response = await api.get('/dashboard/GetTopTaskPerformers');
        if (response.status === 200) {
          this.topContributors = response.data.map(item => ({
            name: item.key,
            taskCount: item.value,
          }));
        } else {
          console.error('Failed to get top contributors');
        }
      } catch (error) {
        console.error('Error while getting top contributors', error);
      }
    },
    async getContactsCount() {
      try {
        const response = await api.get('/dashboard/GetContactsCount');
        if (response.status === 200) {
          
          // Handle the contacts count as needed
        } else {
          console.error('Failed to get contacts count');
        }
      } catch (error) {
        console.error('Error while getting contacts count', error);
      }
    },
    async getUncompletedTasksCount() {
      try {
        const response = await api.get('/dashboard/GetUncompletedTasksCount');
        if (response.status === 200) {
          // Handle the uncompleted tasks count as needed
        } else {
          console.error('Failed to get uncompleted tasks count');
        }
      } catch (error) {
        console.error('Error while getting uncompleted tasks count', error);
      }
    },
    async getLast5Tasks() {
      try {
        const response = await api.get('/dashboard/GetLast5Tasks');
        if (response.status === 200) {
          // Handle the last 5 tasks as needed
        } else {
          console.error('Failed to get last 5 tasks');
        }
      } catch (error) {
        console.error('Error while getting last 5 tasks', error);
      }
    },
  },
};
</script>

<style scoped>
.v-card-title {
  font-weight: bold;
}
.v-divider {
  margin: 0.5rem 0;
}
.v-card-text {
  padding-top: 0;
}
</style>
